import axios, {AxiosResponse} from 'axios';
import IMenu from '../models/IMenu';

export const getMenus = async () => {
  const {
    data: {data: menus},
  }: AxiosResponse<{data: IMenu[]}> = await axios.get(
    `${process.env.STRAPI_API_URL}/menus?populate=children`,
  );

  return menus.sort((a, b) => a.attributes.position - b.attributes.position);
};
