import React from 'react';
import {QueryClientProvider, Hydrate} from '@tanstack/react-query';
import {queryClient} from '../../constants/queryClient';
import {Queries} from '../../constants/queries';
import {getMenus} from '../../creates/menus';
import {getWidgets} from '../../creates/widgets';

export const RootElement = ({children}: {children: React.ReactNode}) => {
  queryClient.prefetchQuery({queryKey: [Queries.Menus], queryFn: getMenus});
  queryClient.prefetchQuery({
    queryKey: [Queries.Widgets],
    queryFn: getWidgets,
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate>
        {children}
      </Hydrate>
    </QueryClientProvider>
  );
};
