import React from 'react';
import {GatsbyBrowser} from 'gatsby';

import './src/index.scss';
import {RootElement} from './src/components/Root';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <RootElement>{element}</RootElement>;
};
