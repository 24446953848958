import axios, {AxiosResponse} from 'axios';
import IWidget from '../models/IWidget';

export const getWidgets = async () => {
  const {
    data: {data: widgets},
  }: AxiosResponse<{data: IWidget[]}> = await axios.get(
    `${process.env.STRAPI_API_URL}/widgets`,
  );

  return widgets.sort((a, b) => a.id - b.id);
};
